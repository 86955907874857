<template>
  <!--
    The default visualiser: visualises a videoDirector config and makes it editable if the edit-prop is set to true.
  -->
  <VisualiserFrame
    v-if="laneConfig"
    :left="getMaxSize(-((svgWidth / 2) - positionModifier.x), true, true)"
    :right="getMaxSize((svgWidth / 2) + positionModifier.x, false, true)"
    :top="getMaxSize((svgHeight / 2) - positionModifier.y, false, false)"
    :bottom="getMaxSize(-((svgHeight / 2) + positionModifier.y), true, false)"
    :show-legend="showLegend"
    :legends="filterLegends"
    :display-all-axes="true"
  >
    <div class="videoDirectorConfigDefaultVisualiser">
      <div class="plusMinusWrapper border rounded">
        <button
          v-tooltip.left="$t('zoomIn')"
          class="btn btn-secondary rounded-0 border-0 p-2"
          @click="zoomIn()"
        >
          <font-awesome-icon
            icon="plus"
          />
        </button>
        <div v-if="zoomFactor !== 1">
          <div class="delimiter" />
          <button
            v-tooltip.left="$t('zoomOut')"
            class="btn btn-secondary rounded-0 border-0 p-2"
            @click="zoomOut()"
          >
            <font-awesome-icon
              icon="minus"
              style="vertical-align: middle; color: #6f727d;"
            />
          </button>
        </div>
        <div v-if="zoomFactor !== 1">
          <div class="delimiter" />
          <button
            v-tooltip.left="$t('fitToView')"
            class="btn btn-secondary rounded-0 border-0 p-2"
            @click="clearZoom()"
          >
            <font-awesome-icon
              icon="expand-arrows-alt"
              style="vertical-align: middle; color: #6f727d;"
            />
          </button>
        </div>
      </div>

      <svg
        ref="videDirectorConfigDefaultVisualiserSvg"
        :viewBox="`${ svgPosition.x } ${ svgPosition.y } ${ svgWidth } ${ svgHeight }`"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="mainSvg cursor-default"
      >

        <Grid
          :width="unscaledWidth"
          :height="unscaledHeight"
          :columns="76"
          :rows="36"
          :center="true"
          :anchorIsCenter="true"
        />

        <!-- preview position -->
        <circle
          v-if="previewPosition"
          :cx="previewPosition.pan"
          :cy="-previewPosition.tilt"
          :r="2.5 * zoomFactor"
          fill="lightblue"
        />

        <!-- current position -->
        <circle
          v-if="ptHeadPosition"
          :cx="ptHeadPosition.pan"
          :cy="-ptHeadPosition.tilt"
          :r="3.5 * zoomFactor"
          fill="red"
        />

        <!-- sort the elements so that the selected one is at the top for dragging -->
        <template v-for="(order, orderIndex) in elementOrder">
          <g :key="`order-${ orderIndex }`">
            <DrawZoomMap
              v-if="zoomMap"
              ref="zoomMap"
              :zoomMap="zoomMap"
            />
            <template v-if="order === 'flowAreas'">
              <template
                v-for="(item, index) in sortedFlowAreas"
              >
                <flowArea
                  ref="flowAreas"
                  :key="`flowArea-${ index }`"
                  :flowArea="item"
                  :edit="editFlow && item.id === selectedFlowAreaId"
                  :color="getFlowColor(item.id)"
                  :scale="scale"
                  :zoom-factor="zoomFactor"
                  @changePtz="changePtz"
                />
              </template>
            </template>

            <zoomPoint
              v-if="order === 'zoomPoints'"
              ref="zoomPoint"
              :zoomPoints="laneConfig.zoomPoints"
              :zoomMap="zoomMap"
              :edit="editZoom"
              :color="getZoomColor()"
              :scale="scale"
              :zoom-factor="zoomFactor"
              @changePtz="changePtz"
            />

            <borderArea
              v-if="order === 'borderArea'"
              ref="borderArea"
              :borderArea="laneConfig.borderArea"
              :edit="editBorder"
              :color="getBorderColor()"
              :scale="scale"
              :zoom-factor="zoomFactor"
              @changePtz="changePtz"
            />
            
            <template v-if="order === 'triggers'">
              <template
                v-for="(item, index) in sortedTriggers"
              >
                <trigger
                  ref="triggers"
                  :key="`trigger-${ index }`"
                  :area="item.area"
                  :edit="editTrigger && item.id === selectedTriggerId"
                  :color="getTriggerColor(item.id)"
                  :scale="scale"
                  :zoom-factor="zoomFactor"
                  @changePtz="changePtz"
                />
              </template>
            </template>

          </g>
        </template>
      </svg>
    </div>
  </VisualiserFrame>
</template>

<script>
import interact from 'interactjs';

export default {
  name: 'VideoDirectorConfigDefaultVisualiser',
  components: {
    VisualiserFrame: () => import('./VisualiserFrame.vue'),
    Grid: () => import('./Svg/Grid.vue'),
    ZoomPoint: () => import('./Svg/ZoomPoint.vue'),
    DrawZoomMap: () => import('./Svg/ZoomMap.vue'),
    BorderArea: () => import('./Svg/BorderArea.vue'),
    FlowArea: () => import('./Svg/FlowArea.vue'),
    Trigger: () => import('./Svg/Trigger.vue')
  },
  props: {
    laneConfig: {
      type: Object,
      required: false,
      default () {
        return null;
      }
    },
    ptHeadPosition: {
      type: Object,
      required: false,
      default () {
        return null;
      }
    },
    previewPosition: {
      type: Object,
      required: false,
      default () {
        return null;
      }
    },
    selectedFlowAreaId: {
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    selectedTriggerId: {
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    editBorder: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    editZoom: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    editFlow: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    editTrigger: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    showLegend: {
      type: Boolean,
      required: false,
      default () {
        return true;
      }
    },
    zoomMap: {
      type: Array,
      required: false,
      default () {
        return [];
      }
    },
    zoomToBorder: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    } 
  },
  data () {
    return {
      scale: { scaleX: 1, scaleY: 1 },
      zoomFactor: 1,
      positionModifier: { x: 0, y: 0 },
      inactiveColor: "#cccccc",
      legends: [
        { name: "flowArea", color: "#00c0ef" },
        { name: "initArea", color: "#00c0ef" },
        { name: "releaseArea", color: "darkorange" },
        { name: "zoomPoint", color: "green" },
        { name: "previewPosition", color: "lightblue" },
        { name: "currentPosition", color: "red" },
        { name: "borderArea", color: "lightcoral" },
        { name: "turnOffArea", color: "darkred" },
        { name: "motionDetectionArea", color: "purple" },
        { name: "trigger", color: "deeppink" },
      ],
      elementOrder: [ "borderArea", "zoomPoints", "triggers", "flowAreas" ]
    }
  },
  computed: {
    filterLegends () {
      let items = [];

      if (this.ptHeadPosition) {
        items.push({ name: "currentPosition", color: "red" });
      }

      if (this.editBorder) {
        let legend = this.legends.find(o => o.name === 'borderArea');
        items.push(legend);
      }

      if (this.editZoom) {
        let legend = this.legends.find(o => o.name === 'zoomPoint');
        items.push(legend);
      }
      if (this.editTrigger) {
        let legend = this.legends.find(o => o.name === 'trigger');
        items.push(legend);
      }
      if (this.editFlow) {
        let legend = this.legends.find(o => o.name === 'initArea');
        items.push(legend);
        legend = this.legends.find(o => o.name === 'releaseArea');
        items.push(legend);
        legend = this.legends.find(o => o.name === 'turnOffArea');
        items.push(legend);
        legend = this.legends.find(o => o.name === 'trigger');
        items.push(legend);
      }
      return items;
    },
    sortedTriggers () {
      let items = this.laneConfig.triggers;
      if (!this.editTrigger) {
        items = items.filter(o => o.active);
      }

      return this.concatToEnd(items, this.selectedTriggerId);
    },
    sortedFlowAreas () {
      let items = this.laneConfig.flowAreas;
      if (!this.editTrigger) {
        items = items.filter(o => o.active);
      }

      return this.concatToEnd(items, this.selectedFlowAreaId);
    },
    svgPosition () {
      let position = { x: this.svgWidth / -2, y: this.svgHeight / -2 };
      position.x += this.positionModifier.x;
      position.y += this.positionModifier.y;
      return position;
    },
    svgWidth () {
      return this.unscaledWidth * this.zoomFactor;
    },
    svgHeight () {
      return this.unscaledHeight * this.zoomFactor;
    },
    unscaledWidth () {
      return 340;
    },
    unscaledHeight () {
      return 160;
    }
  },
  created () {
    this.elementOrder = [ "flowAreas", "triggers", "borderArea", "zoomPoints" ];

    if (this.editTrigger) {
      this.elementOrder = [ "borderArea", "zoomPoints", "flowAreas", "triggers" ];
    }
    if (this.editFlow) {
      this.elementOrder = [ "borderArea", "zoomPoints", "triggers", "flowAreas" ];
    }
  },
  mounted () {
    window.addEventListener("resize", this.calculateScale);
    this.listenToVideDirectorConfigDefaultVisualiserSvg();
    setTimeout(this.calculateScale, 500);

    if(this.zoomToBorder && this.$refs.borderArea)
    {
      // let rootRect = this.$refs.borderArea[0].$refs.borderAreaPolygon.getBoundingClientRect();  
      // console.log(this.$refs.borderArea[0].$refs.borderAreaPolygon);   
      // let scaleX = 0; 
      // let scaleY = 0 
      // if(rootRect.width > this.svgWidth)
      // {
      //   scaleX = this.svgWidth / rootRect.width;
      // }
      // else
      // {
      //   scaleX = rootRect.width / this.svgWidth;
      // }
      // if(rootRect.height > this.svgHeight)
      // {
      //   scaleY = this.svgHeight / rootRect.height;
      // }
      // else
      // {
      //   scaleY = rootRect.height / this.svgHeight;
      // }
      // console.log("rootRect.width: " + rootRect.width);
      // console.log("rootRect.height: " + rootRect.height);
      // console.log("svgWidth: " + this.svgWidth);
      // console.log("svgHeight: " + this.svgHeight);
      // console.log("scaleX: " + scaleX);
      // console.log("scaleY: " + scaleY);
      // let newZoomFactor = (scaleX + scaleY) / 2;
      this.zoomFactor = 0.75; // hardcoded zoom for the moment 
      //console.log("newZoom: " + newZoomFactor);
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.calculateScale);
    
    if (this.$refs.videDirectorConfigDefaultVisualiserSvg) {
      interact(this.$refs.videDirectorConfigDefaultVisualiserSvg).off('doubletap', this.doubleTap);
      interact(this.$refs.videDirectorConfigDefaultVisualiserSvg).unset();
    }
  },
  methods: {
    getMaxSize (val, minus, width) {
      if (width) {
        if(minus) {
          return val < -170 ? -170 : val;
        }
        else {
          return val > 170 ? 170 : val;
        }
      }
      else {
        if(minus) {
          return val < -80 ? -80 : val;
        }
        else {
          return val > 80 ? 80 : val;
        }
      }
    },
    concatToEnd (arr, id) {
      if (!arr) {
        return null;
      }

      return arr.filter(x => {
        return x.id !== id;
      }).concat(arr.filter(x => {
        return x.id === id;
      })
      );
    },
    getZoomColor () {
      if (!this.editZoom) {
        return this.inactiveColor;
      }

      return this.legends.find(o => o.name === 'zoomPoint').color;
    },
    getBorderColor () {
      if (!this.editBorder) {
        return this.inactiveColor;
      }

      return this.legends.find(o => o.name === 'borderArea').color;
    },
    getFlowColor (flowId) {
      if (!this.editFlow) {
        return this.inactiveColor;
      }

      if (this.selectedFlowAreaId === flowId) {
        return this.legends.find(o => o.name === 'flowArea').color;
      }

      return this.inactiveColor;
    },
    getTriggerColor (triggerId) {
      if (!this.editTrigger && this.zoomToBorder) {
        return this.legends.find(o => o.name === 'trigger').color;
      }

      if (this.selectedTriggerId === triggerId) {
        return this.legends.find(o => o.name === 'trigger').color;
      }

      return this.inactiveColor;
    },
    update () {
      if (this.$refs.borderArea[0]) {
        this.$refs.borderArea[0].update();
      }
      if (this.$refs.zoomPoint[0]) {
        this.$refs.zoomPoint[0].update();
      }
      if (this.$refs.flowAreas) {
        this.$refs.flowAreas.forEach(o => {
          o.update();
        });
      }
      if (this.$refs.triggers) {
        this.$refs.triggers.forEach(o => {
          o.update();
        });
      }
    },
    calculateScale () {
      if (!this.$refs.videDirectorConfigDefaultVisualiserSvg) {
        return;
      }
      let rootRect = this.$refs.videDirectorConfigDefaultVisualiserSvg.getBoundingClientRect();

      let scaleX = this.svgWidth / rootRect.width;
      let scaleY = this.svgHeight / rootRect.height;

      this.scale = {
        scaleX: scaleX,
        scaleY: scaleY
      };
    },
    zoomIn () {
      this.zoomFactor *= 0.75;
      this.calculateScale();
    },
    zoomOut () {
      if (this.zoomFactor * 1.25 >= 1) {
        this.zoomFactor = 1;
        this.clearZoom();
      } else {
        this.zoomFactor *= 1.25;
      }
      this.calculateScale();
    },
    clearZoom () {
      this.zoomFactor = 1;
      this.positionModifier.x = 0;
      this.positionModifier.y = 0;
      this.calculateScale();
    },
    doubleTap (event) {
      let position = {
        pan: event.offsetX * this.scale.scaleX + (this.svgWidth / -2) + this.positionModifier.x,
        tilt: -(event.offsetY * this.scale.scaleY + this.svgHeight /-2) - this.positionModifier.y
      }
      this.changePtz(position);
    },
    changePtz (position) {
      this.$emit('changePtz', position);
    },
    listenToVideDirectorConfigDefaultVisualiserSvg () {
      this.$nextTick(() => {
        if (this.$refs.videDirectorConfigDefaultVisualiserSvg) {
          interact(this.$refs.videDirectorConfigDefaultVisualiserSvg)
            .on('doubletap', this.doubleTap);
          interact(this.$refs.videDirectorConfigDefaultVisualiserSvg)
            .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
              onmove: (event) => {
                if ((this.svgWidth / -2) + this.positionModifier.x - (event.dx * this.scale.scaleX) > -170 && (this.svgWidth / 2) + this.positionModifier.x - (event.dx * this.scale.scaleX) < 170) {
                  this.positionModifier.x -= event.dx * this.scale.scaleX;
                }
                if ((this.svgHeight / -2) + this.positionModifier.y - (event.dy * this.scale.scaleY) > -80 && (this.svgHeight / 2) + this.positionModifier.y - (event.dy * this.scale.scaleY) < 80) {
                  this.positionModifier.y -= event.dy * this.scale.scaleY;
                }
              }
            });
        } else {
          setTimeout(this.listenToVideDirectorConfigDefaultVisualiserSvg, 15);
        }
      });
    }
  }
}
</script>

<style scoped>
.videoDirectorConfigDefaultVisualiser {
  overflow: hidden;
  position: relative;
}
svg {
  overflow: visible;
}
.svgZoomRect {
  fill: lightgray;
}
.svgZoomText {
  fill: gray;
}
.svgZoomWrapper:hover .svgZoomText {
  fill: black;
}
.svgZoom {
  fill: white;
}
.plusMinusWrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
}
.plusMinusWrapper > button {
  display: block;
}
.plusMinusWrapper .delimiter {
  height: 1px;
  margin: auto;
  width: 75%;
  background: lightgray;
}
</style>
